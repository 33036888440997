<template>
  <div ref="root" class="relative">
    <div class="input-container" :class="{ 'is-open': showSuggestions }">
      <input
        type="text"
        placeholder="Search by topic or skill"
        class="input placeholder-gray-500 focus:placeholder-gray-400"
        :value="value"
        @focus="canOpenSuggestion = true"
        @keydown.enter="onKeydownEnter"
        @keydown.down="onKeydownDown"
        @keydown.up="onKeydownUp"
        @input="onInput"
      />

      <div class="px-6 py-5 cursor-pointer bg-white" @click="onKeydownEnter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="icon-right"
        >
          <path
            d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
          />
        </svg>
      </div>
    </div>
    <transition
      enter-active-class="transition-all transition-fastest ease-out-quad"
      leave-active-class="transition-all transition-faster ease-in-quad"
      enter-class="transform opacity-0 skew-x-6"
      enter-to-class="transform opacity-100 skew-x-0"
      leave-class="transform opacity-100 skew-x-0"
      leave-to-class="transform opacity-0 skew-x-6"
    >
      <div
        v-if="showSuggestions"
        class="absolute origin-top right-0 w-full bg-white rounded-b-xl border border-gray-300 shadow-xl py-2 -mt-1"
      >
        <ul>
          <li v-for="(item, idx) in suggestions" :key="`${item}-${idx}`">
            <div
              class="block w-full cursor-pointer text-left px-6 py-2 text-sm font-medium text-gray-600 hover:bg-gray-200 hover:text-black"
              :class="{ 'bg-gray-200': isHighlighted(idx) }"
              @click="() => selectSuggestion(item)"
            >
              {{ item }}
            </div>
          </li>
        </ul>
      </div>
    </transition>
    <p
      v-if="exampleText"
      class="text-xs font-medium text-gray-600 tracking-tight mt-3 ml-6 text-left"
    >
      {{ exampleText }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    exampleText: {
      type: String,
      default: null
    },
    suggestions: {
      type: Array,
      default: null
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      current: null,
      canOpenSuggestion: false
    };
  },

  computed: {
    showSuggestions() {
      return (
        this.suggestions && this.suggestions.length && this.canOpenSuggestion
      );
    }
  },

  mounted() {
    document.body.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  },

  methods: {
    /**
     * Habdle click away
     */
    handleDocumentClick(event) {
      if (!this.$refs.root.contains(event.target)) {
        this.canOpenSuggestion = false;
        this.current = null;
      }
    },

    /**
     * If we click on a suggestion directly
     */
    selectSuggestion(item) {
      this.$emit('input', item);
      this.$emit('onSelectSuggestion', item);
      this.canOpenSuggestion = false;
      this.current = null;
    },

    onInput($event) {
      this.$emit('input', $event.target.value);
      this.canOpenSuggestion = true;
    },

    // When 'enter' pressed.
    // Either validate the input, or the suggestions
    onKeydownEnter() {
      if (this.current !== null) {
        this.selectSuggestion(this.suggestions[this.current]);
      } else {
        this.$emit('onEnter', this.value);
      }
    },

    // When up pressed while suggestions are open
    onKeydownUp() {
      if (this.current > 0) {
        this.current--;
      } else if (this.current === 0) {
        this.current = null;
      } else {
        this.current = this.suggestions.length - 1;
      }
    },

    // When up pressed while suggestions are open
    onKeydownDown() {
      if (this.current === null) {
        this.current = 0;
      } else if (this.current === this.suggestions.length - 1) {
        this.current = null;
      } else if (this.current < this.suggestions.length - 1) {
        this.current++;
      }
    },

    // For highlighting element
    isHighlighted(index) {
      return index === this.current;
    }
  }
};
</script>

<style lang="postcss" scoped>
.icon-right {
  @apply fill-current text-red-500 transition-colors duration-150 ease-in-out;
}

.input-container {
  @apply flex justify-center align-middle overflow-hidden transition-all duration-150 ease-in-out border border-gray-200 rounded-xl mx-auto;

  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);

  &.is-open {
    @apply rounded-b-none border-gray-300;
  }

  &:hover {
    box-shadow: 0 24px 40px -8px rgba(50, 50, 93, 0.25),
      0 14px 24px -14px rgba(0, 0, 0, 0.3),
      0 -10px 24px -7px rgba(0, 0, 0, 0.025);

    .icon-right {
      @apply text-red-600;
    }
  }

  &:focus-within {
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
      0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);

    .icon-right {
      @apply text-red-500;
    }
  }
}
</style>
