// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules_dev/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules_dev/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/blur-2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "footer[data-v-7d565040]{display:flex;flex-direction:column;justify-content:center;align-items:center;position:relative;overflow:hidden;background-color:hsla(0,0%,97.6%,.7);-webkit-backdrop-filter:saturate(180%) blur(12px);backdrop-filter:saturate(180%) blur(12px)}footer[data-v-7d565040]:before{position:absolute;bottom:-40%;right:-30%;content:\"\";width:100%;height:80%;transform:rotate(35deg);opacity:.3;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;z-index:0}@media (min-width:768px){footer[data-v-7d565040]:before{bottom:-40%;right:-15%;content:\"\";width:60%;height:100%;transform:rotate(0deg)}}", ""]);
// Exports
module.exports = exports;
